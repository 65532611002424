import React from "react"

const NotFound = () => {
  return (
    <div>
      <h2>404</h2>
      <p>This page does not exist</p>
    </div>
  )
}

export default NotFound
